import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, List,
} from '../../components/opComponents';

const MaladieDeDupuytren = () => (
  <Layout
    title="Maladie de Dupuytren : Diagnostic et traitement"
    description="La maladie de Dupuytren a été décrite par le Baron Guillaume de Dupuytren. Cette maladie touche l'aponévrose palmaire et limite progressivement l'extension des doigts."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Maladie de Dupuytren" />
        <MainPart title="Qu'est-ce que la maladie de Dupuytren ?">
          <Typography weight="bold">
            La maladie de Dupuytren
            {' '}
            <Typography variant="span">
              a été décrite par le Baron Guillaume de Dupuytren
              en 1831. Cette maladie touche l&apos;aponévrose palmaire et
            </Typography>
            {' '}
            limite progressivement l&apos;extension des doigts.
          </Typography>
        </MainPart>
        <MainPart title="Qu'est-ce qu'une aponévrose ?">
          <Typography>
            L&apos;aponévrose palmaire est une couche de tissu située sous la peau qui a
            pour rôle de protéger les nerfs, les vaisseaux, les tendons et les
            muscles de la main. Dans
            {' '}
            <Typography variant="span" weight="bold">
              la maladie de Dupuytren,
            </Typography>
            {' '}
            cette aponévrose s&apos;épaissit et est à l&apos;origine de l&apos;apparition
            de «nodules» et de «brides» au niveau de la main.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les causes possibles de la maladie de Dupuytren ?">
          <Typography>
            L&apos;origine de la maladie de Dupuytren reste inconnue. Elle serait, au moins,
            en partie génétique (prédominance dans le nord de la France et en Europe du nord).
            Plusieurs pathologies sont associées à
            {' '}
            <Typography variant="span" weight="bold">
              la maladie de Dupuytren :
            </Typography>
            {' '}
            l&apos;épilepsie, le diabète, l&apos;hypertriglycéridémie,
            la consommation d&apos;alcool et le
            tabac. Le travail manuel n&apos;est en aucun cas responsable de la maladie de Dupuytren.
            Par contre, cette maladie peut se développer dans les suites d&apos;un
            {' '}
            <Typography variant="span" weight="bold">
              traumatisme de la main.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Comment est diagnostiquée la maladie de Dupuytren ?">
          <div className="flex flex-col">
            <Typography>
              Le diagnostic de la maladie de Dupuytren est clinique. Elle
              siège préférentiellement au
              niveau des 4èmes et 5èmes doigts (tous les doigts peuvent être atteints)
              et est indolore.
              Les brides sont à l&apos;origine d&apos;une perte progressive
              de l&apos;extension des doigts.
              La fermeture de la main reste possible car les tendons fléchisseurs sont intacts.
            </Typography>
            <Typography>
              La main est examinée minutieusement afin de localiser les nodules et les brides.
              La qualité de la peau en regard des brides et le déficit d&apos;extension de chaque
              articulation doivent être notés lors du bilan pré-opératoire.
            </Typography>
          </div>
          <FigureDouble
            maxWidth="max-w-[350px]"
            height="h-[150px]"
            imageNameOne="figure1-maladiededupuytren.jpg"
            imageNameTwo="figure1bis-maladiededupuytren.jpg"
            caption="Figure 1. Maladie de Dupuytren de l&apos;auriculaire. Notez la présence d&apos;une importante bride au niveau de la paume responsable d&apos;une importante perte de l&apos;extension du doigt."
            currentOpTitle="Maladie de Dupuytren"
          />
          <div className="flex flex-col">
            <Typography>
              Dans les formes sévères, les patients peuvent être porteurs de nodules au
              niveau de la plante des pieds (maladie de Ledderhose) et/ou avoir une
              déformation du sexe chez les hommes (maladie de Lapeyronie) et/ou
              présenter des coussinets au niveau du dos des doigts.
            </Typography>
            <Typography>
              Les facteurs de mauvais pronostic de la maladie de Dupuytren sont :
              âge jeune, antécédent familial de Dupuytren, maladie de Ledderhose/Lapeyronie
              et une forme digitale pure (en particulier du 5ème doigt).
            </Typography>
            <Typography>
              L&apos;évolution de la maladie de Dupuytren est imprévisible et souvent par
              palier. Le mode évolutif à long terme est la récidive, quel que soit le traitement.
            </Typography>
          </div>
        </MainPart>
        <MainPart title="Quels sont les examens qui confirment la maladie de Dupuytren ?">
          <Typography>
            Aucun examen complémentaire n&apos;est nécessaire pour
            le diagnostic et la prise en charge de la maladie.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux pour la maladie de Dupuytren ?">
          <Typography weight="bold">
            Médical :
            {' '}
            <Typography variant="span">
              en France, il n&apos;existe pas de traitement médical de la maladie de Dupuytren.
              L&apos;injection de Collagenase Clostridium Histolyticum n&apos;est pas remboursée
              par la sécurité sociale en raison du service médical rendu jugé insuffisant
              par la Haute Autorité de Santé.
            </Typography>
          </Typography>
          <Typography weight="bold">
            Chirurgical :
            {' '}
            <Typography variant="span">
              Le traitement chirurgical consiste à sectionner ou retirer les tissus malades.
              L&apos;origine de la maladie étant inconnue, le traitement chirurgical ne peut,
              à lui seul, empêcher l&apos;extension de la maladie
              à d&apos;autres doigts voire la récidive
              sur les doigts opérés. Les formes débutantes ou discrètes ne doivent pas être
              opérées. Cependant, il ne faut pas trop attendre car dans les formes très
              évoluées, le traitement est plus difficile, plus risqué avec un risque de récidive
              plus important.
            </Typography>
            <Typography variant="span">
              L&apos;intervention est indiquée lorsque le patient ne peut plus poser la main à plat
              sur une table. Elle est le plus souvent réalisée sous anesthésie loco-régionale
              (le bras est uniquement endormi), en ambulatoire (pas d&apos;hospitalisation).
            </Typography>
          </Typography>
          <Typography>
            Trois types de techniques chirurgicales peuvent être proposées :
          </Typography>
          <Typography weight="bold">
            La fasciotomie ou bridotomie percutanée :
            {' '}
            <Typography variant="span">
              section de la bride à l&apos;aide d&apos;une aiguille
              ou d&apos;une lame de bistouri. La bridotomie
              est indiquée en cas de bride superficielle au niveau de la paume de la main. Cette
              technique a pour avantage d&apos;être simple,
              rapide et ainsi permettre un usage précoce
              de la main. Le principal inconvénient est le taux de récidive qui est le plus
              élevé par rapport aux autres techniques chirurgicales.
            </Typography>
          </Typography>
          <Typography weight="bold">
            La fasciectomie :
            {' '}
            <Typography variant="span">
              ablation de la bride. L&apos;incision cutanée est réalisée en zigzag au
              niveau de la paume de la main et des doigts concernés (Figure 2).
            </Typography>
          </Typography>
          <Figure
            imageName="figure2-maladiededupuytren.jpg"
            caption="Figure 2. Incision cutanée en lignes brisées permettant la dissection des brides en sécuritéLa dissection est faite sous loupes chirurgicales permettant de retirer la bride en respectant les nerfs, vaisseaux et les tendons (Figure 3 et 4)"
            currentOpTitle="Maladie de Dupuytren"
          />
          <Figure
            imageName="figure3-maladiededupuytren.jpg"
            caption="Figure 3. Aspect final après exérèse de la maladie de Dupuytren. Notez l&apos;intégralité des nerfs et des vaisseaux en fin d&apos;intervention"
            currentOpTitle="Maladie de Dupuytren"
          />
          <Figure
            imageName="figure4-maladiededupuytren.jpg"
            caption="Figure 4. Maladie de Dupuytren retiré"
            currentOpTitle="Maladie de Dupuytren"
          />
          <Typography>
            Dans certains cas, une partie de la cicatrice peut être laissée ouverte
            {' '}
            (
            <Typography variant="span" weight="bold">
              technique de «Mac-Cash »
            </Typography>
            )
            {' '}
            afin de diminuer les complications cutanées et la survenue d&apos;hématome. Parfois
            la réalisation d&apos;un lambeau peut être nécessaire : la peau est déplacée d&apos;une
            zone saine, afin de couvrir les nerfs, vaisseaux et tendons.
          </Typography>
          <Typography weight="bold">
            La dermofasciectomie :
            {' '}
            <Typography variant="span">
              ablation de la bride et de la peau en regard associée à une greffe de peau. Elle
              est indiquée en cas de récidive ou d&apos;emblée dans les formes sévères
              avec infiltration
              cutanée. La greffe de peau est prélevée au niveau de la face interne du bras ou
              de l&apos;avant-bras.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les suites post-opératoires après une opération chirurgicale pour la maladie de Dupuytren ?">
          <Typography>
            Les pansements sont réalisés tous les deux jours.
            {' '}
            <Typography variant="span" weight="bold">
              La cicatrisation cutanée est obtenue au bout de 15 jours.
            </Typography>
            {' '}
            En cas de zones laissées en cicatrisation dirigées ou en cas de greffe de peau,
            la cicatrisation est plus longue, pouvant aller jusqu&apos;à 1mois.
            {' '}
            <Typography variant="span" weight="bold">
              La sensation de fourmillement au bout des doigts peut durer plusieurs semaines.
            </Typography>
            {' '}
            La rééducation post-opératoire est essentielle pour récupérer la flexion et
            l&apos;extension des doigts. Le port d&apos;une
            attelle dynamique d&apos;extension trois fois par jour
            durant 1 heure (+/- la nuit si bien tolérée) est conseillée pendant quelques semaines,
            afin de faciliter le gain en extension des doigts.
            Il s&apos;agit d&apos;une attelle sur-mesure,
            réalisée après l&apos;opération par un orthésiste. Il est important de noter que
            l&apos;extension complète n&apos;est pas toujours possible à obtenir.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour la maladie de Dupuytren ?">
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Douleurs post-opératoires : fréquentes pendant le
                premier mois suivant l&apos;opération.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Fourmillement des doigts : le plus souvent liés à une dissection des
                nerfs avant de retirer les tissus malades.
                L&apos;irritation nerveuse peut persister quelques semaines.
                Exceptionnellement les troubles peuvent être liés à une
                section nerveuse nécessitant une suture en urgence.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Résultat incomplet : en cas de formes sévères ou récidivantes.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Récidive : relativement fréquente. Une ou plusieurs interventions
                espacées de quelques mois ou années restent possibles.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Amputation : en rapport avec une section des vaisseaux, elle peut être
                observée dans les formes sévères, multi-opérées notamment du 5ème doigt.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default MaladieDeDupuytren;
